import CASL from '@/utils/constants/casl'

export default {
  path: 'group',
  children: [
    {
      path: '',
      name: 'group.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GROUP
      },
      component: () => import('./list/GroupListPage.vue')
    }
  ]
}
