import CASL from '@/utils/constants/casl'

export default {
  path: 'pb-pd-promo',
  children: [
    {
      path: '',
      name: 'pb-pd-promo.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.PB_PD_PROMO
      },
      component: () => import('./list/PbPdListPage.vue')
    }
  ]
}
