import CASL from '@/utils/constants/casl'

export default {
  path: 'input-no-registrasi',
  children: [
    {
      path: '',
      name: 'input-no-registrasi.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.INPUT_NO_REGISTRASI
      },
      component: () => import('./list/InputNoRegistrasiListPage.vue')
    },
    {
      path: ':uid',
      name: 'input-no-registrasi.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.INPUT_NO_REGISTRASI
      },
      component: () => import('./form/InputNoRegistrasiFormPage.vue')
    }
  ]
}
