import CASL from '@/utils/constants/casl'

export default {
  path: 'type-charger',
  children: [
    {
      path: '',
      name: 'type-charger.list',
      meta: {
        action: 'read',
        // subject: CASL.ACCESS.MOBILE_APP_SETUP
        // subject: CASL.ACCESS.PUBLIC
        subject: CASL.ACCESS.DASHBOARD
      },
      component: () => import('./list/TypeChargerListPage.vue')
    }
  ]
}
