import CASL from '@/utils/constants/casl'

export default {
  path: 'survey-offline',
  children: [
    {
      path: '',
      name: 'survey-offline.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.SURVEY_OFFLINE
      },
      component: () => import('./list/SurveyOfflineListPage.vue')
    },
    {
      path: ':uid',
      name: 'survey-offline.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.SURVEY_OFFLINE
      },
      component: () => import('./form/SurveyOfflineFormPage.vue')
    }
  ]
}
