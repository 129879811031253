import CASL from '@/utils/constants/casl'

export default {
  path: 'warranty',
  children: [
    {
      path: '',
      name: 'warranty.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./list/WarrantyListPage.vue')
    },
    {
      path: ':uid',
      name: 'warranty.edit',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./detail/DetailWarranty.vue')
    }
  ]
}
