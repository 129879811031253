import CASL from '@/utils/constants/casl'

export default {
  path: 'app-content',
  children: [
    {
      path: '',
      name: 'app-content.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.MOBILE_APP_SETUP
      },
      component: () => import('./list/AppContentList.vue')
    }
  ]
}
