import CASL from '@/utils/constants/casl'

export default {
  path: 'penawaran-so',
  children: [
    {
      path: '',
      name: 'penawaran-so.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.PENAWARAN_SO
      },
      component: () => import('./list/PenawaranSoListPage.vue')
    },
    {
      path: ':uid',
      name: 'penawaran-so.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.PENAWARAN_SO
      },
      component: () => import('./form/PenawaranSoFormPage.vue')
    }
  ]
}
