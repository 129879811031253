import CASL from '@/utils/constants/casl'

export default {
  path: 'instalasi',
  children: [
    {
      path: '',
      name: 'instalasi.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.INSTALASI
      },
      component: () => import('./list/InstalasiListPage.vue')
    },
    {
      path: ':uid',
      name: 'instalasi.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.INSTALASI
      },
      component: () => import('./form/InstalasiFormPage.vue')
    }
  ]
}
