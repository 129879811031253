import CASL from '@/utils/constants/casl'

export default {
  path: 'charger-diterima',
  children: [
    {
      path: '',
      name: 'charger-diterima.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.CHARGER_DITERIMA
      },
      component: () => import('./list/ChargerDiterimaListPage.vue')
    },
    {
      path: ':uid',
      name: 'charger-diterima.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.CHARGER_DITERIMA
      },
      component: () => import('./form/ChargerDiterimaFormPage.vue')
    }
  ]
}
