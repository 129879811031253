import CASL from '@/utils/constants/casl'

export default {
  path: 'approval-chassisnumber',
  children: [
    {
      path: '',
      name: 'approval-chassisnumber.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.APPROVAL_NO_RANGKA
      },
      component: () => import('./list/ApproveChassisNumberListPage.vue')
    },
    {
      path: ':uid',
      name: 'approval-chassisnumber.edit',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.APPROVAL_NO_RANGKA
      },
      component: () => import('./form/ApprovalChassisNumberFormPage.vue')
    }
  ]
}
