const ACCESS = {
  DASHBOARD: 'DASHBOARD',
  TASK_OVERVIEW: 'TASK_OVERVIEW',
  ATPM: 'ATPM',
  USER: 'USER',
  TEAM: 'USER',
  ROLE: 'USER',
  DATA_INPUT: 'DATA_INPUT',
  SERIAL_NUMBER: 'SERIAL_NUMBER',
  CONFIRMATION: 'KONFIRMASI',
  SURVEY: 'SURVEY',
  SURVEY_OFFLINE: 'SURVEY_OFFLINE',
  PROCESS: 'PROSES_PLN',
  DELIVERY: 'DELIVERY',
  PLN_MOBILE: 'PLN_MOBILE',
  INSTALLATION: 'INSTALASI',
  DATA_PROBLEM: 'DATA_PROBLEM',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  OPTION_LIBRARY: 'OPSI_LIBRARY',
  MOBILE_APP_SETUP: 'MOBILE_APP_SETUP',
  GARANSI: 'GARANSI',
  BYD_APPROVAL: 'BYD_APPROVAL',
  MATERIAL_TAMBAHAN: 'MATERIAL_TAMBAHAN',
  DAYA_LISTRIK_PLN: 'DAYA_LISTRIK_PLN',
  BUNDLING: 'BUNDLING',
  INPUT_NO_REGISTRASI: 'INPUT_NO_REGISTRASI',
  ATPM_USER: 'ATPM_USER',
  DEALER_USER: 'DEALER_USER',
  APPROVAL_NO_RANGKA: 'APPROVAL_NO_RANGKA',
  GROUP: 'GROUP',
  DEALER: 'DEALER',
  PB_PD_PROMO: 'PB_PD_PROMO',
  // Task
  SURVEY_MATERIAL: 'SURVEY_MATERIAL',
  SURVEY_DAYA: 'SURVEY_DAYA',
  PENAWARAN_SO: 'PENAWARAN_SO',
  PROSES_PLN: 'PROSES_PLN',
  CHARGER_DITERIMA: 'CHARGER_DITERIMA',
  INSTALASI: 'INSTALASI'
}

export default { ACCESS }
