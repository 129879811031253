import CASL from '@/utils/constants/casl'

export default {
  path: 'warranty-subscribe',
  children: [
    {
      path: '',
      name: 'warranty-subscribe.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./list/WarrantySubscriberListPage.vue')
    },
    {
      path: 'add',
      name: 'warranty-subscribe.add',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./form/WarrantySubscribeFormPage.vue')
    },
    {
      path: ':uid',
      name: 'warranty-subscribe.edit',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./form/WarrantySubscribeFormPage.vue')
    }
  ]
}
