import CASL from '@/utils/constants/casl'

export default {
  path: 'survey-daya',
  children: [
    {
      path: '',
      name: 'survey-daya.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.SURVEY_DAYA
      },
      component: () => import('./list/SurveyDayaListPage.vue')
    },
    {
      path: ':uid',
      name: 'survey-daya.detail',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.SURVEY_DAYA
      },
      component: () => import('./form/SurveyDayaFormPage.vue')
    }
  ]
}
