import SurveyOffline from './survey-offline/routes'
import SurveyMaterial from './survey-material/routes'
import SurveyDaya from './survey-daya/routes'
import PenawaranSo from './penawaran-so/routes'
import Pln from './pln/routes'
import Delivery from './delivery/routes'
import ChargerDiterima from './charger-diterima/routes'
import Instalasi from './instalasi/routes'
import InputNoRegistrasi from './input-no-registrasi/routes'

export default {
  path: 'task-v2',
  children: [
    SurveyOffline,
    SurveyMaterial,
    SurveyDaya,
    PenawaranSo,
    Pln,
    Delivery,
    ChargerDiterima,
    Instalasi,
    InputNoRegistrasi
  ]
}
