import CASL from '@/utils/constants/casl'

export default {
  path: 'bundling',
  children: [
    {
      path: '',
      name: 'bundling.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.BUNDLING
      },
      component: () => import('./list/BundlingListPage.vue')
    },
    {
      path: 'add',
      name: 'bundling.add',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.BUNDLING
      },
      component: () => import('./form/BundlingFormPage.vue')
    },
    {
      path: ':uid',
      name: 'bundling.edit',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.BUNDLING
      },
      component: () => import('./form/BundlingFormPage.vue')
    }
  ]
}
