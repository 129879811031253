import CASL from '@/utils/constants/casl'

export default {
  path: 'atpm',
  children: [
    {
      path: '',
      name: 'atpm.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.ATPM
      },
      component: () => import('./list/AtpmListPage.vue')
    },
    {
      path: 'add',
      name: 'atpm.add',
      meta: {
        action: 'read', // TODO: Should be 'create'?
        subject: CASL.ACCESS.ATPM
      },
      component: () => import('./form/AtpmFormPage.vue')
    },
    {
      path: ':uid',
      name: 'atpm.edit',
      meta: {
        action: 'read', // TODO: Should be 'update'?
        subject: CASL.ACCESS.ATPM
      },
      component: () => import('./form/AtpmFormPage.vue')
    }
  ]
}
