import CASL from '@/utils/constants/casl'

export default {
  path: 'material-tambahan',
  children: [
    {
      path: '',
      name: 'material-tambahan.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.MATERIAL_TAMBAHAN
      },
      component: () => import('./list/MaterialTambahanListPage.vue')
    }
  ]
}
