import CASL from '@/utils/constants/casl'

export default {
  path: 'warranty-t&c',
  children: [
    {
      path: '',
      name: 'warranty-t&c.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.GARANSI
      },
      component: () => import('./list/WarrantyT&CPage.vue')
    }
  ]
}
