import CASL from '@/utils/constants/casl'

export default {
  path: 'daya-listrik',
  children: [
    {
      path: '',
      name: 'pln-power.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.DAYA_LISTRIK_PLN
      },
      component: () => import('./list/PlnPowerListPage.vue')
    }
  ]
}
