import CASL from '@/utils/constants/casl'

export default {
  path: 'atpm-user',
  children: [
    {
      path: '',
      name: 'atpm-user.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.ATPM_USER
      },
      component: () => import('./list/AtpmUserListPage.vue')
    }
  ]
}
